import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/@next+third-parties@14.2.16_next@14.2.16_react@18.3.1/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/@next+third-parties@14.2.16_next@14.2.16_react@18.3.1/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/@next+third-parties@14.2.16_next@14.2.16_react@18.3.1/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Mate\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"],\"variable\":\"--font-mate\",\"display\":\"swap\"}],\"variableName\":\"mate\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Sarabun\",\"arguments\":[{\"weight\":\"200\",\"subsets\":[\"latin\"],\"variable\":\"--font-sarabun\",\"display\":\"swap\"}],\"variableName\":\"sarabun\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/NavigationMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/ScrollToTopButton.tsx");
